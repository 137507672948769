.shop_page {
	color: white;
}

.shop_type {
	border-bottom: solid;
	border-color: #f26522;
}

.shopping_section a:visited {
	color: #fff;
}

.shopping_section {
	display: flex;
	flex-wrap: wrap;
	margin: 0 auto 3% 2%;
	gap: 2%;

	/* border: 1px #ccc solid; */
}

.part_card {
	display: flex;
	flex-direction: column;
	/* border: 1px #ccc solid; */
	flex: 0 0 10%;

	justify-content: space-between;
	margin: 0 0 3% -1%;
	/*
	flex-grow: 1;
	*/
}

.part_card > .part_img {
	align-items: center;
}

.part_card .part_name {
	margin: 5% 0 4% 3%;
	width: fit-content;
	color: #fff;
}

.part_card .part_price {
	margin: 5% 0 4% 3%;
	width: fit-content;
}

.part_img {
	display: flex;

	width: 200px;
	height: 200px;
	/* width: calc(100vw - 1350px);
	height: calc(100vh - 250%); */

	background-color: #fff;
	border-radius: 25px;
	overflow: hidden;

	align-items: center;
	justify-content: center;
}

.part_card img {
	vertical-align: middle;

	max-height: 100%;
	max-width: 100%;
}

.part_link {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.part_card button {
	background-color: #f26522;
	color: white;
	font-size: 16px;
	padding: 12px 16px;
	border-radius: 20px;
	border: 2px solid #f26522;
	transition-duration: 0.2s;
	margin: 5% 0 5% 0;
}
.part_card button:hover {
	opacity: 80%;
	cursor: pointer;
}

.shop_type {
	font-weight: bold;
	font-size: 36px;
	margin-bottom: 3%;
}

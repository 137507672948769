.footer_section {
	display: flex;
	flex-direction: column;
	margin: 5% auto 5% auto;
	padding: 0;
	align-items: center;
}

.footer_section p {
	margin: 0.5%;
	color: #777;
}

.footer_section .logo_text {
	font-weight: bold;
	font-size: 20px;
	color: #fff;
}

.footer_section a {
	color: #777;
}

.footer_section a:hover{
	color:#F26522;
}

.upper_footer {
	display: flex;
	align-self: center;
	width: 75%;
	padding: 3%;
	justify-content: center;

	border-top: solid;
	border-bottom: solid;
	border-width: medium;
	border-color: #f26522;
}

.upper_footer > * {
	flex: 1;
}

.upper_footer ul {
	padding: 0px 10px;
	list-style-type: none;
}

.upper_footer li {
	margin: 0 0 15px 0;
}

.upper_footer .logo {
	display: flex;
	align-items: center;
	margin-right: 5%;
}

/* Shopping section */
.shop_section {
	margin: 0 5%;
}

.shop_section .logo_text {
	margin-bottom: 25%;
}

.shop_section ul{
	color: #777;
	padding-top: 0px;

	font-weight: bold;
	font-size: 16px;
}

/* Reach us section */
.reachus_section {
	margin: 0 5%;
}

.reachus_section .logo_text {
	margin-bottom: 25%;
}

.reachus_section ul {
	padding-top: 0px;

	font-weight: bold;
	font-size: 16px;
	color: #777;
}

/* Company section */
.company_section {
	margin: 0 5%;
}

.company_section .logo_text {
	margin-bottom: 10%;
}

.company_section ul {
	padding-top: 0px;

	font-weight: bold;
	font-size: 16px;
	color: #777;
}

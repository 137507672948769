.category_section {
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
}

.card {
	flex: 0 0 10%;
}

.card {
	margin: 0 0 5% 0;
}

.card div {
	margin: 5% 0% 10% 0%;
	color: white;
	font-weight: bold;
	font-size: 20px;
}

.crop {
	width: 15vw;
	height: 50vh;
	overflow: hidden;
	border-radius: 25px;
	overflow: hidden;
}
.crop img {
	width: 15vw;
	height: 65vh;
	object-fit: cover;
	background-position: center;
	opacity: 0.4;
	transition: 0.55s ease;
	overflow: hidden;
}

.cat_img:hover {
	transform: scale(1.2);
	transition: 0.55s;
	opacity: 1;
	overflow: hidden;
}

.cat_name:hover {
	color: #f26522;
}

.header_section {
	display: flex;
	align-items: center;
	justify-content: space-between;

	font-weight: bold;
	font-size: 20px;
	color: #fff;

	border-style: solid;
	border-width: 3px 0 0 0;
	border-color: #f26522;

	margin: 3% auto 3% auto;
	padding: 1%;
}

.header_section a {
	color: #777;
}

.header_section a:hover {
	color: #f26522;
}

.header_section .logo {
	display: flex;
	align-items: center;
}

.header_section .nav_elements {
	display: flex;
	gap: 15%;
	align-items: center;
}

.header_section .nav_elements a + a {
	margin-left: 10px;
}

.logo > * {
	padding: 0 10% 0 0;
}

.nav_elements {
	display: inline;
	width: fit-content;
	word-break: normal;
}

.nav_elements > *:hover {
	color: #f26522;
}

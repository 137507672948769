* {
	font-family: Helvetica, Arial, sans-serif;
	text-decoration: none;
	margin: 0;
	padding: 0;
}

body {
	background-color: #1f1e1e;
}

.container-1 {
	/* display: flex;
	flex-direction: column; */

	width: 80%;
	margin: 3% auto;
}

.footer-comp {
	margin-top: auto;
}

.hero {
	overflow: hidden;
	position: relative;
}

.hero video {
	max-width: 150vh;
	max-height: 100vh;
	position: absolute;
	top: 70%;
	left: 100%;
	transform: translateX(-70%) translateY(-60%);
	z-index: -1;
}

.video {
	max-width: 100%;
	max-height: 80%;
	border-radius: 25px;
	overflow: hidden;
}

.hero .hero_content {
	z-index: 1;
}

.categories {
	margin: 5% 0 3% 0;
}

.categories p {
	color: white;
	font-weight: bold;
	font-size: 24px;
}

.vid_wrapper {
	border-radius: 25px;
	overflow: hidden;
}

.logo_img {
	width: 3vw;
}
/* .container-1 div {
	border: 1px #ccc solid;
} */

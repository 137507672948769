.au h2 {
	color: white;
}

.au h3 {
	color: white;
	margin-top: 20px;
}

.au p {
	color: white;
	margin-top: 10px;
}

.pp h2 {
	color: white;
	margin-top: 40px;
}

.pp h3 {
	color: white;
	margin-top: 20px;
}

.pp p {
	color: white;
	margin-top: 10px
}

.pp ul {
	color: white;
	margin-left: 20px;
	margin-top: 10px;
}

.am h2 {
	color: white;
	margin-top: 40px;
}

.am h3 {
	color: white;
	margin-top: 20px;
}

.am p {
	color: white;
	margin-top: 10px
}